/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useRef, useEffect, useLayoutEffect, useState, useMemo, ReactElement } from 'react';

import { Alert, Table, Button, Dropdown, Space, Popconfirm, Input, Tooltip, Menu } from 'antd';
import { ColumnsType, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import { useUploads } from 'context/uploads';
import moment from 'moment';

import {
  PlusCircleOutlined,
  DeleteOutlined,
  DropboxOutlined,
  AuditOutlined,
  RollbackOutlined,
  MedicineBoxOutlined,
  CheckCircleTwoTone,
  FileOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
  CarOutlined,
  InfoCircleOutlined,
  DownOutlined,
  SolutionOutlined,
  CheckCircleOutlined,
  FileDoneOutlined,
  PrinterOutlined,
  BarcodeOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  SwapOutlined,
  QrcodeOutlined,
} from 'components/icons';

import {
  AdminPendingOrderStatuses,
  DEVICE_STATUS,
  LABELING_STRATEGIES,
  PROJECT_STATUSES,
  PendingOrderStatuses,
  USER_ROLES,
} from 'constants/index';
import { useAuth } from 'context/auth';
import { IDeviceLot, useDeviceLots } from 'context/deviceLots';
import { IDevice, useDevices } from 'context/devices';
import { IPatient } from 'context/patients';
import {
  FILTER_SHOW_ALL,
  NONE_TEMPLATE,
  PatientPagingConfig,
  SummarizedOrderTemplate,
} from 'features/Projects/Project/ProjectShipments';
import useBackendColumnSearch from 'hooks/useBackendColumnSearch';
import useDeviceBulkEditModal from 'hooks/useDeviceBulkEditModal';
import useWindowSize from 'hooks/useWindowSize';
import { getReplacementReason, isPendingOrder, openDocument } from 'utils/functions';
import Notification from 'utils/notification';
import { IProject } from 'context/projects';
import { IProjectLaboratory } from 'context/projectLaboratories';
import { Order } from 'context/orders';
import { Laboratory } from 'context/laboratories';

import { useOneClickPrint } from 'context/oneClickPrint';
import { downloadShippingLabelDisposition } from 'api/shippingLabels';
import { downloadBarcodeDisposition } from 'api/downloads';
import { OrderFulfillmentType } from '@tassoinc/core-api-client';
import { CloseSquareOutlined, ProfileOutlined } from '@ant-design/icons';
import SelectOrderOrderTemplate from './orderTemplateSelect';

interface DeviceRecord extends IDevice {
  lot: string;
  isPlaceholder?: boolean;
  isDevice: true;
  key: string;
}

interface PatientRecord {
  hasReplacementDeviceReadyToShip: boolean;
  id: string;
  key: string;
  patient: IPatient;
  isDevice: boolean;
  hasPendingOrder: boolean;
  hasLabels: boolean;
  children: DeviceRecord[];
}

type TableRecord = PatientRecord | DeviceRecord;
type EditPatientLinkProps = {
  patient: IPatient;
  onEditPatient: (patient: IPatient) => void;
};

const isDeviceRecord = (record: TableRecord): record is DeviceRecord => !!record.isDevice;
const EditPatientLink: FC<EditPatientLinkProps> = React.memo(({ patient, onEditPatient }) => (
  <Button
    type="default"
    size="small"
    shape="circle"
    icon={<EditOutlined />}
    className="edit-patient-link"
    onClick={() => onEditPatient(patient)}
  />
));

interface ISelectedPatient {
  id: string;
  deviceIds: string[];
}

type IShipmentRecipientsList = {
  selectedDeviceIds: string[];
  onSelectDevices: (selectedDeviceIds: string[]) => void;
  selectedPatients: ISelectedPatient[];
  onSelectPatients: (selectedPatients: ISelectedPatient[]) => void;
  onSelectPatientOrder: (id: string[]) => void;
  patients: IPatient[];
  devices: IDevice[];
  deviceLots: IDeviceLot[];
  loading: boolean;
  packingInstructions: Record<string, string>;
  createShippingLabels: (disposition?: string) => void;
  generateBarcodes: (disposition?: string) => void;
  cancelShippingLabels: () => void;
  generateRequisitionForm: (disposition?: string) => void;
  generateOrderSummaryForm: (disposition?: string) => void;
  generateCustomerLetters: (disposition?: string) => void;
  generateActivationLabels: (disposition?: string) => void;
  generatePrintables: (download: boolean) => void;
  removePatients: () => void;
  addNewDevice: () => void;
  cancelOrder: () => void;
  deleteDevice: () => void;
  onCreatePatient: () => void;
  onEditPatient: (patient: IPatient) => void;
  isFiltered: boolean;
  projectId: string;
  totalPatientCount: number;
  pagingConfig: PatientPagingConfig | null;
  updatePagingConfig: (config: Partial<PatientPagingConfig>) => void;
  reloadPage: (resetSelection: boolean) => Promise<void>;
  project: IProject;
  projectLaboratories: IProjectLaboratory[];
  patientIdsWithPendingOrders: string[];
  orders: Order[];
  laboratory: Laboratory;
  summarizedOrderTemplates: SummarizedOrderTemplate[];
  selectedOrderTemplateId: string;
  setSelectedOrderTemplateId: (orderTemplateId: string) => void;
};
const ShipmentRecipientsList: FC<IShipmentRecipientsList> = ({
  selectedDeviceIds,
  onSelectDevices,
  selectedPatients,
  onSelectPatients,
  onSelectPatientOrder,
  patients,
  devices,
  deviceLots,
  loading,
  packingInstructions,
  createShippingLabels,
  generateBarcodes,
  cancelShippingLabels,
  generateRequisitionForm,
  generateOrderSummaryForm,
  generateCustomerLetters,
  generateActivationLabels,
  generatePrintables,
  removePatients,
  addNewDevice,
  cancelOrder,
  deleteDevice,
  onCreatePatient,
  onEditPatient,
  isFiltered,
  projectId,
  totalPatientCount,
  pagingConfig,
  updatePagingConfig,
  reloadPage,
  project,
  projectLaboratories,
  patientIdsWithPendingOrders,
  orders,
  laboratory,
  summarizedOrderTemplates,
  selectedOrderTemplateId,
  setSelectedOrderTemplateId,
}) => {
  const { profile } = useAuth();
  const isAdmin = (profile?.role || '') === USER_ROLES.internalAdmin;
  const isCustomerService = (profile?.role || '') === USER_ROLES.internalCustomerService;
  const isShipper = (profile?.role || '') === USER_ROLES.internalShipper;

  const isArchivedProject = project.status === PROJECT_STATUSES.archived;
  const orderingDisabledForProject = !project.allowOrdering;

  const { getColumnSearchProps } = useBackendColumnSearch<any>();
  const { updateDevice, setLoading: setDeviceLoading } = useDevices();
  const { addDeviceLot } = useDeviceLots();
  const { getUpload } = useUploads();
  const { Modal: DeviceBulkEditModal, showModal: showDeviceBulkEditModal } = useDeviceBulkEditModal();
  const [deviceHashByDeviceId, setDeviceHashByDeviceId] = useState<{ [deviceId: string]: number }>({});
  const [deviceHashByPatientId, setDeviceHashByPatientId] = useState<{ [patientId: string]: number[] }>({});
  const [deviceLotsHash, setDeviceLotsHash] = useState<{ [deviceLotId: string]: number }>({});
  const [editingDevice, setEditingDevice] = useState<{ id: string; field: string; value: string }>({
    id: '',
    field: '',
    value: '',
  });
  const [dataConfig, setDataConfig] = useState<{
    sortedInfo: Record<string, any> | null;
    filteredInfo: Record<string, any> | null;
  }>({
    sortedInfo: { order: 'ascend', columnKey: 'shipmentDate' },
    filteredInfo: null,
  });

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>({
    current: 0,
    defaultCurrent: 1,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 100,
    hideOnSinglePage: false,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (n) => `Total ${n} patients`,
  });

  const tableWrapRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [tableHeight, setTableHeight] = useState<number>(1);

  // Create an object for faster order lookup. The key is order's id, the value
  // is the order itself.
  const [ordersByOrderId, setOrdersByOrderId] = useState<Record<string, Order>>({});

  const { isOneClickPrintEnabled } = useOneClickPrint();

  const getReqFormDisposition = () => (isOneClickPrintEnabled ? 'attachment;filename=requisition.pdf' : undefined);
  const getCustomerLettersDisposition = () =>
    isOneClickPrintEnabled ? 'attachment;filename=customer_letter.pdf' : undefined;
  const getBarcodesDisposition = () => (isOneClickPrintEnabled ? downloadBarcodeDisposition : undefined);
  const getOrderSummaryDisposition = () => (isOneClickPrintEnabled ? 'attachment;filename=of_summary.pdf' : undefined);
  const getShipLabelDisposition = () => (isOneClickPrintEnabled ? downloadShippingLabelDisposition : undefined);
  const getActivationLabelsDisposition = () =>
    isOneClickPrintEnabled ? 'attachment;filename=activation_label.pdf' : undefined;

  useEffect(() => {
    setDeviceHashByDeviceId(devices.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {}));

    const deviceHash: { [patientId: string]: number[] } = {};
    for (let i = 0; i < devices.length; i += 1) {
      const { patientId } = devices[i];
      if (deviceHash[patientId]) {
        deviceHash[patientId].push(i);
      } else {
        deviceHash[patientId] = [i];
      }
    }

    setDeviceHashByPatientId(deviceHash);
  }, [devices]);

  useEffect(() => {
    setDeviceLotsHash(
      deviceLots.reduce((acc, val, index) => ({ ...acc, [val.id]: index }), {} as { [deviceLotId: string]: number })
    );
  }, [deviceLots]);

  useEffect(() => {
    setTablePagination((s) => ({ ...s, total: totalPatientCount }));
  }, [totalPatientCount]);

  useEffect(() => {
    const ids = patients.map((p) => p.id);
    onSelectPatientOrder(ids);
  }, [patients]);

  const resetPagination = () => {
    updatePagingConfig({
      page: tablePagination.defaultCurrent!,
      pageLength: tablePagination.defaultPageSize!,
      sortBy: 'deviceReadyToShipOccurredAt',
      sortOrder: 'asc',
      includeTotalCount: true,
      search: null,
    });

    setTablePagination((s) => ({
      ...s,
      current: tablePagination.defaultCurrent!,
      pageSize: tablePagination.defaultPageSize!,
    }));
  };

  useEffect(() => {
    setOrdersByOrderId(orders.reduce((acc, order) => ({ ...acc, [order.id]: order }), {}));
  }, [orders]);

  useEffect(() => {
    resetPagination();
  }, []);

  const searchConfig = pagingConfig?.search || null;

  const searchByKey = async (searchKey: string, term: string | null) => {
    if (searchConfig !== null && searchConfig.by === searchKey) {
      // search previously performed on this field, now reset or update it
      if (term === null) {
        resetPagination();
      } else {
        updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      }
    } else if (term) {
      updatePagingConfig({ search: { by: searchKey, term }, page: 1, includeTotalCount: true });
      setTablePagination((s) => ({ ...s, current: 1 }));
    }
  };

  const getDeviceById = (deviceId: string): IDevice | null => devices[deviceHashByDeviceId[deviceId]] || null;

  const getDevicesByPatientId = (patientId: string): IDevice[] =>
    (deviceHashByPatientId[patientId] || []).map((index) => devices[index]).filter((v) => v !== undefined);

  useLayoutEffect(() => {
    if (tableWrapRef.current) {
      const tBody = tableWrapRef.current.querySelector('.ant-table-body');
      const tFooter = tableWrapRef.current.querySelector('.ant-table-footer');
      if (!tBody || !tFooter) {
        return;
      }
      const bodyBox = tBody.getBoundingClientRect();
      const footerBox = tFooter.getBoundingClientRect();

      // 64px is the height of the website footer
      // 32px height of pagination
      // 32px margin around pagination
      // 16px ShipmentRecipientsList bottom padding
      const h = windowSize.height - bodyBox.top - footerBox.height - 64 - 32 - 32 - 16;
      setTableHeight(h);
    }
  }, [windowSize]);

  const handleBulkUpdateDevices = () => {
    showDeviceBulkEditModal(selectedDeviceIds, () => reloadPage(true));
  };

  const handleUpdateDevice = (device: IDevice): void => {
    if (editingDevice.field === 'lot') {
      const lotValue = editingDevice.value.trim();
      const lotValueNormalized = lotValue.toLowerCase();

      const existingLot = deviceLots.find((lot) => lot.key.toLowerCase() === lotValueNormalized);

      if (existingLot) {
        updateDevice(device.id, { deviceLotId: existingLot.id });
      } else {
        addDeviceLot(lotValue, (newLot: IDeviceLot) => {
          updateDevice(device.id, { deviceLotId: newLot.id });
        });
      }
      setEditingDevice({ id: '', field: '', value: '' });
      return;
    }

    if (editingDevice.field === 'expiresAt') {
      const dayOnlyValue = moment(editingDevice.value).format('YYYY-MM-DD');
      const dayOnlyToday = moment(moment.now()).format('YYYY-MM-DD');
      if (moment(dayOnlyValue).isBefore(dayOnlyToday)) {
        setEditingDevice({ id: '', field: '', value: '' });
        Notification({
          type: 'error',
          message: 'Device was not updated',
          description: 'Expiration date cannot be in the past.',
        });
        return;
      }
    }

    const params = { [editingDevice.field]: editingDevice.value === '' ? null : editingDevice.value };
    updateDevice(device.id, params, async () => {
      if (!isAdmin && 'status' in params && params.status === 'awaitingPickup') {
        // internalShipper is moving device to awaitingPickup, we need to reload
        await reloadPage(true);
      }
    });
    setEditingDevice({ id: '', field: '', value: '' });
  };

  // Used for the device tooltip - map device orderTemplateId to order template friendly name
  function getOrderTemplateNameForDeviceOrderTemplateId(orderTemplateId?: string): string {
    if (!orderTemplateId) {
      return 'None';
    }

    const result = summarizedOrderTemplates?.find((ot) => orderTemplateId.includes(ot.id));
    if (result) {
      return result.name ?? orderTemplateId;
    }
    return `Contact Support: Missing ${orderTemplateId}`;
  }

  const updateFieldValue = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const val = e.target.value;
    setEditingDevice((v) => ({ ...v, value: val }));
  }, []);

  const canEditPatient = (record: PatientRecord): boolean =>
    !isArchivedProject && !isCustomerService && (isAdmin || !record.hasLabels);

  const isDeviceThirdParty = (device?: DeviceRecord): boolean =>
    device !== undefined &&
    device.orderId !== null &&
    ordersByOrderId[device.orderId]?.fulfillmentType !== OrderFulfillmentType.Tasso;

  /** Be up front and transparent about barcode editability */
  const isBarcodeEditable = (device?: DeviceRecord): boolean => {
    if (isArchivedProject) {
      return false;
    }

    if (!['barcode', 'barcodePrelabeled'].includes(project.labelIdentifierSource)) {
      return false;
    }

    if (device && device.orderId && ordersByOrderId[device.orderId]?.fulfillmentType !== OrderFulfillmentType.Tasso) {
      return false;
    }

    if (project.labelIdentifierSource === 'barcodePrelabeled') {
      if (!device) {
        return false;
      }
      return ['inStock', 'readyToShip', 'awaitingPickup'].includes(device.status);
    }
    // should just be customer provided barcode, which we don't limit assignment
    return true;
  };

  const getDeviceBarcodeDefaultValue = (device: DeviceRecord) => device.barcode || '';

  const isDeviceProcessing = (device: DeviceRecord): boolean => {
    let isProcessing = device.status === 'createdForOrder';

    if (isAdmin || isCustomerService) {
      // For admins we want to show orders that are in `logisticsComplete` to allow for
      // selecting the order if they want to cancel it.
      const orderForDevice = device.orderId ? ordersByOrderId[device.orderId] : undefined;

      if (orderForDevice) {
        isProcessing = isProcessing && AdminPendingOrderStatuses.has(orderForDevice.status);
      }
    }

    return isProcessing;
  };

  /**
   * Returns Device details based on the column number.
   * This is required as First name might be hidden, so Device details need to be shifted to the next column.
   */
  function getDeviceRow(device: DeviceRecord, columnIndex: number): ReactElement | null {
    if (device.isPlaceholder) {
      return null;
    }

    switch (columnIndex) {
      case 0:
        return (
          <Tooltip title={getOrderTemplateNameForDeviceOrderTemplateId(device.orderTemplateId ?? undefined)}>
            {device.replacesDeviceId ? <strong>Replacement Device</strong> : <span>Device</span>}
            {device.hasLabels ? <CheckCircleTwoTone style={{ color: '#004e92' }} /> : null}
          </Tooltip>
        );

      case 1:
        // The main Device details.
        return (
          <table className="DeviceBarcodeContainer">
            <tbody>
              <tr>
                <td>
                  <strong>Barcode: </strong>
                </td>
                <td id={`barcode-${device.id}`}>
                  {device.id === editingDevice.id && editingDevice.field === 'barcode' ? (
                    <div>
                      <Input
                        id={`txt-barcode-${device.id}`}
                        placeholder="Scan or type barcode"
                        size="small"
                        defaultValue={getDeviceBarcodeDefaultValue(device)}
                        onChange={updateFieldValue}
                        onFocus={(e) => e.target.select()}
                        autoFocus
                        allowClear
                        autoSave="true"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleUpdateDevice(device);
                          }
                        }}
                      />

                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => handleUpdateDevice(device)}
                          disabled={editingDevice.value !== '' && editingDevice.value === device.barcode}
                        >
                          <SaveOutlined />
                        </Button>
                      )}
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => {
                            setEditingDevice({ id: '', field: '', value: '' });
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      {getDeviceBarcodeDefaultValue(device) || <em>not set</em>}
                      {(isAdmin || isShipper) && isBarcodeEditable(device) && (
                        <Button
                          type="link"
                          id={`edit-barcode-${device.id}`}
                          onClick={() => setEditingDevice({ id: device.id, field: 'barcode', value: device.barcode })}
                        >
                          <EditOutlined />
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Lot: </strong>
                </td>
                <td>
                  {device.id === editingDevice.id && editingDevice.field === 'lot' ? (
                    <div>
                      <Input
                        placeholder="Lot number"
                        size="small"
                        defaultValue={device.lot || ''}
                        onChange={updateFieldValue}
                        allowClear
                      />
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => handleUpdateDevice(device)}
                          disabled={editingDevice.value !== '' && editingDevice.value === device.lot}
                        >
                          <SaveOutlined />
                        </Button>
                      )}
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => {
                            setEditingDevice({ id: '', field: '', value: '' });
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      {device.lot || <em>not set</em>}
                      {(isAdmin || isShipper) && !isArchivedProject && !isDeviceThirdParty(device) && (
                        <Button
                          type="link"
                          onClick={() => setEditingDevice({ id: device.id, field: 'lot', value: device.lot })}
                        >
                          <EditOutlined />
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Created: </strong>
                </td>
                <td>{device.createdAt}</td>
              </tr>
              <tr>
                <td>
                  <strong>Expires: </strong>
                </td>
                <td>
                  {device.id === editingDevice.id && editingDevice.field === 'expiresAt' ? (
                    <div>
                      <Input
                        placeholder="YYYY-MM-DD"
                        size="small"
                        defaultValue={device.expiresAt}
                        onChange={updateFieldValue}
                        allowClear
                      />
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => handleUpdateDevice(device)}
                          disabled={editingDevice.value !== '' && editingDevice.value === device.expiresAt}
                        >
                          <SaveOutlined />
                        </Button>
                      )}
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => {
                            setEditingDevice({ id: '', field: '', value: '' });
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      {device.expiresAt || <em>not set</em>}
                      {(isAdmin || isShipper) && !isArchivedProject && !isDeviceThirdParty(device) && (
                        <Button
                          type="link"
                          onClick={() =>
                            setEditingDevice({ id: device.id, field: 'expiresAt', value: device.expiresAt })
                          }
                        >
                          <EditOutlined />
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Status: </strong>
                </td>
                <td>
                  {device.id === editingDevice.id && editingDevice.field === 'status' ? (
                    <div>
                      <Dropdown
                        trigger={['click']}
                        placement="bottomCenter"
                        overlay={
                          <Menu onClick={(e) => updateFieldValue({ target: { value: e.key } } as any)}>
                            <Menu.Item key="inStock">{DEVICE_STATUS.inStock}</Menu.Item>
                            <Menu.Item key="readyToShip">{DEVICE_STATUS.readyToShip}</Menu.Item>
                            <Menu.Item key="awaitingPickup">{DEVICE_STATUS.awaitingPickup}</Menu.Item>
                            <Menu.Item key="inTransitToPatient">{DEVICE_STATUS.inTransitToPatient}</Menu.Item>
                            <Menu.Item key="atPatient">{DEVICE_STATUS.atPatient}</Menu.Item>
                            <Menu.Item key="inTransitToLab">{DEVICE_STATUS.inTransitToLab}</Menu.Item>
                            <Menu.Item key="atLab">{DEVICE_STATUS.atLab}</Menu.Item>
                            <Menu.Item key="resultsReady">{DEVICE_STATUS.resultsReady}</Menu.Item>
                          </Menu>
                        }
                      >
                        {(isAdmin || isShipper) && (
                          <Button className="default-button">
                            {DEVICE_STATUS[editingDevice.value as keyof typeof DEVICE_STATUS] || ''} <DownOutlined />
                          </Button>
                        )}
                      </Dropdown>
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => handleUpdateDevice(device)}
                          disabled={editingDevice.value !== '' && editingDevice.value === device.status}
                        >
                          <SaveOutlined />
                        </Button>
                      )}
                      {(isAdmin || isShipper) && (
                        <Button
                          type="link"
                          onClick={() => {
                            setEditingDevice({ id: '', field: '', value: '' });
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <>
                      {device.status ? DEVICE_STATUS[device.status as keyof typeof DEVICE_STATUS] : <em>not set</em>}
                      {(isAdmin || isShipper) && !isArchivedProject && (
                        <Button
                          type="link"
                          onClick={() => setEditingDevice({ id: device.id, field: 'status', value: device.status })}
                        >
                          <EditOutlined />
                        </Button>
                      )}
                    </>
                  )}
                </td>
              </tr>
              {device.isReplaced && (
                <tr>
                  <td>
                    <strong>Replaced:</strong>
                  </td>
                  <td>
                    Yes{' '}
                    <Tooltip title={getReplacementReason(device.replacementReason)}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </td>
                </tr>
              )}
              {(isAdmin || isCustomerService) && device.orderId && ordersByOrderId[device.orderId] && (
                <tr>
                  <td>
                    <strong>Fulfillment:</strong>
                  </td>
                  <td>{ordersByOrderId[device.orderId!].fulfillmentType}</td>
                </tr>
              )}
            </tbody>
          </table>
        );

      default:
        return null;
    }
  }

  /**
   * Determine the column span for the Device details Table based on the number of visible columns.
   * */
  function getVisibleOptionalColumns(): number {
    // We are only counting columns to the right of "Last name", since that is where the Device table begins.
    let visibleColumns = 0;
    if (project.patientPropertyConfig?.email !== 'hidden') {
      visibleColumns += 1;
    }

    if (project.patientPropertyConfig?.phoneNumber !== 'hidden') {
      visibleColumns += 1;
    }

    if (project.patientPropertyConfig?.dob !== 'hidden') {
      visibleColumns += 1;
    }

    if (project.patientPropertyConfig?.subjectId !== 'hidden') {
      visibleColumns += 1;
    }

    return visibleColumns;
  }

  /**
   * Columns are displayed in the order in which they are listed.
   * Be careful about changing the order of "First Name", "Last name" and "Device Count" columns as the device details depends on this order.
   * If columns are added/removed, check the "colSpan" property to make sure it aligns with the current setup.
   */
  const columns: ColumnsType<TableRecord> = [
    ...(project.patientPropertyConfig?.firstName !== 'hidden'
      ? [
          {
            key: 'firstName',
            title: 'First name',
            render: (_: any, record: TableRecord) => {
              if (isDeviceRecord(record)) {
                // Get the 1st column of the Device record.
                return getDeviceRow(record, 0);
              }

              return (
                <>
                  {record.patient.firstName || '-'}
                  {canEditPatient(record) && <EditPatientLink patient={record.patient} onEditPatient={onEditPatient} />}
                </>
              );
            },
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            sortOrder: dataConfig?.sortedInfo?.columnKey === 'firstName' && dataConfig?.sortedInfo?.order,
            ...getColumnSearchProps(searchConfig, 'firstNameLike', searchByKey),
          },
        ]
      : ([] as any)),
    {
      key: 'lastName',
      title: 'Last name',
      render: (_, record) => {
        if (!record.isDevice) {
          // Patient row, show last name with an edit link
          return {
            children: (
              <>
                {record.patient.lastName}
                {canEditPatient(record) && <EditPatientLink patient={record.patient} onEditPatient={onEditPatient} />}
              </>
            ),
            props: {
              colSpan: 1,
            },
          };
        }

        // Device row, display device info
        const device = record as unknown as DeviceRecord;
        const colSpanValue =
          project.patientPropertyConfig?.firstName === 'hidden'
            ? 1 // If "First name" is hidden, just show "Device" text (what "First name" is supposed to do).
            : getVisibleOptionalColumns() + 3; // Optional + (Last name, Device count, Shipment Date)

        // If "First name" is hidden, just show the "Device" text. Otherwise show all Device details.
        if (project.patientPropertyConfig?.firstName === 'hidden') {
          return {
            children: <>{getDeviceRow(device, 0)}</>,
            props: {
              colSpan: colSpanValue,
            },
          };
        }

        if (isDeviceProcessing(device)) {
          return {
            children: (
              <table className="DeviceBarcodeContainer">
                <tbody>
                  <tr>
                    <td>Order is being processed.</td>
                  </tr>
                </tbody>
              </table>
            ),
            props: {
              colSpan: colSpanValue,
            },
          };
        }

        return {
          children: <>{getDeviceRow(device, 1)}</>,
          props: {
            colSpan: colSpanValue,
          },
        };
      },
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      sortOrder: dataConfig?.sortedInfo?.columnKey === 'lastName' && dataConfig?.sortedInfo?.order,
      ...getColumnSearchProps(searchConfig, 'lastNameLike', searchByKey),
    },
    {
      key: 'deviceCount',
      title: 'Device Count',
      render: (_, record) => {
        // If "First name" is hidden, show all Device details. Otherwise, show nothing.
        const colSpanValue =
          project.patientPropertyConfig?.firstName === 'hidden'
            ? getVisibleOptionalColumns() + 2 //  Optional + (Device count, Shipment Date)
            : 0; // Show nothing as "First name" and "Last name" cover all the Device details.

        if (isDeviceRecord(record)) {
          if (isDeviceProcessing(record)) {
            return {
              children: (
                <table className="DeviceBarcodeContainer">
                  <tbody>
                    <tr>
                      <td>Order is being processed.</td>
                    </tr>
                  </tbody>
                </table>
              ),
              props: {
                colSpan: colSpanValue,
              },
            };
          }

          return {
            children: <>{project.patientPropertyConfig?.firstName === 'hidden' ? getDeviceRow(record, 1) : null}</>,
            props: {
              colSpan: colSpanValue,
            },
          };
        }

        const devicesWithLabelsCount = record.children.filter((d) => d.hasLabels).length;
        const deviceCount = record.children.filter((d) => !d.isPlaceholder).length;

        // High-vis indicator for shipper view for replacement devices
        let replacementTooltip: React.ReactElement | undefined;
        if (isShipper && record.hasReplacementDeviceReadyToShip) {
          replacementTooltip = (
            <Tooltip title="Replacement Device">
              <SwapOutlined />
            </Tooltip>
          );
        }

        let deviceCountLabel: React.ReactElement | number;

        if (record.hasPendingOrder) {
          deviceCountLabel = (
            <Tooltip title="Order is being processed. Update recipients list to see changes.">
              {deviceCount} <ClockCircleOutlined />
            </Tooltip>
          );
        } else {
          deviceCountLabel = deviceCount;
        }

        if (deviceCount > 0 && deviceCount === devicesWithLabelsCount && !record.hasPendingOrder) {
          return (
            <>
              {replacementTooltip} {deviceCountLabel}{' '}
              <FileDoneOutlined style={{ color: '#004e92', fontSize: 14 }} title="All devices have labels" />
            </>
          );
        }

        if (devicesWithLabelsCount > 0) {
          return (
            <>
              {replacementTooltip} {deviceCountLabel}{' '}
              <FileOutlined style={{ color: '#004e92', fontSize: 14 }} title="Some devices have labels" />
            </>
          );
        }

        return (
          <>
            {replacementTooltip} {deviceCountLabel}
          </>
        );
      },
    },
    ...(project.patientPropertyConfig?.email !== 'hidden'
      ? [
          {
            key: 'email',
            title: 'Email',
            render: (_: any, record: TableRecord) => {
              if (isDeviceRecord(record)) {
                return {
                  props: {
                    colSpan: 0,
                  },
                };
              }

              return (
                <>
                  {record.patient.email || '-'}
                  {canEditPatient(record) && <EditPatientLink patient={record.patient} onEditPatient={onEditPatient} />}
                </>
              );
            },
            ...getColumnSearchProps(searchConfig, 'emailLike', searchByKey),
          },
        ]
      : ([] as any)),
    ...(project.patientPropertyConfig?.phoneNumber !== 'hidden'
      ? [
          {
            key: 'phoneNumber',
            title: 'Phone',
            render: (_: any, record: TableRecord) => {
              if (isDeviceRecord(record)) {
                return {
                  props: {
                    colSpan: 0,
                  },
                };
              }

              return record.patient.phoneNumber || '-';
            },
          },
        ]
      : ([] as any)),
    ...(project.patientPropertyConfig?.dob !== 'hidden'
      ? [
          {
            key: 'dob',
            title: 'DoB',
            render: (_: any, record: TableRecord) => {
              if (!isDeviceRecord(record)) {
                return (
                  <>
                    {record.patient.dob || 'N/A'}
                    {canEditPatient(record) && (
                      <EditPatientLink patient={record.patient} onEditPatient={onEditPatient} />
                    )}
                  </>
                );
              }

              return {
                props: {
                  colSpan: 0,
                },
              };
            },
          },
        ]
      : ([] as any)),
    ...(project.patientPropertyConfig?.subjectId !== 'hidden'
      ? [
          {
            key: 'subjectId',
            title: 'Subject ID',
            render: (_: any, record: TableRecord) =>
              isDeviceRecord(record) ? (
                {
                  props: {
                    colSpan: 0,
                  },
                }
              ) : (
                <>
                  {record.patient.subjectId || '-'}
                  {canEditPatient(record) && <EditPatientLink patient={record.patient} onEditPatient={onEditPatient} />}
                </>
              ),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: dataConfig?.sortedInfo?.columnKey === 'subjectId' && dataConfig?.sortedInfo?.order,
            ...getColumnSearchProps(searchConfig, 'subjectIdLike', searchByKey),
          },
        ]
      : ([] as any)),
    {
      key: 'shipmentDate',
      title: 'Shipment Date',
      render: (_, record) =>
        isDeviceRecord(record)
          ? {
              props: {
                colSpan: 0,
              },
            }
          : record.patient.deviceReadyToShipOccurredAt,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: dataConfig?.sortedInfo?.columnKey === 'shipmentDate' && dataConfig?.sortedInfo?.order,
      ...getColumnSearchProps(
        searchConfig,
        'deviceReadyToShipOccurredAt',
        searchByKey,
        (v) =>
          v &&
          (moment(v, 'YYYY-MM-DD', true).isValid() ||
            moment(v, 'YYYY-MM', true).isValid() ||
            moment(v, 'YYYY', true).isValid())
            ? v
            : null,
        'datepicker'
      ),
    },
  ];

  const handlePatientRowClick = (record: PatientRecord): void => {
    const patientId = record.patient.id;
    const patientDevices = record.children.filter((device) => !device.isPlaceholder);

    const devicesToSelect: string[] = [];

    // Check if current patient is already selected
    let isPatientSelected = false;

    for (let i = 0; i < selectedPatients.length; i += 1) {
      if (selectedPatients[i].id === patientId) {
        isPatientSelected = true;
        break;
      }
    }

    if (isPatientSelected) {
      // unselect the patient row
      onSelectPatients(selectedPatients.filter((p) => p.id !== patientId));

      // unselect all devices of patient has them
      if (patientDevices.length > 0) {
        const patientDeviceIds: string[] = patientDevices.map((d) => d.id);
        const filteredDeviceIds = selectedDeviceIds.filter((deviceId) => !patientDeviceIds.includes(deviceId));
        devicesToSelect.push(...filteredDeviceIds);
      } else {
        devicesToSelect.push(...selectedDeviceIds);
      }
    } else {
      // select the patient row

      const deviceIds = patientDevices.length > 0 ? [patientDevices[0].id] : [];

      onSelectPatients([
        ...selectedPatients,
        {
          id: patientId,
          deviceIds,
        },
      ]);

      if (deviceIds.length > 0 && !selectedDeviceIds.includes(deviceIds[0])) {
        devicesToSelect.push(...selectedDeviceIds, ...deviceIds);
      } else {
        devicesToSelect.push(...selectedDeviceIds);
      }
    }

    onSelectDevices(devicesToSelect);
  };

  const handleDeviceRowClick = (record: DeviceRecord): void => {
    const currentDeviceId = record.id;
    const currentPatientId = record.patientId;

    const isDeviceSelected = selectedDeviceIds.includes(currentDeviceId);

    if (isDeviceSelected) {
      onSelectDevices(selectedDeviceIds.filter((id) => id !== currentDeviceId));
    } else {
      onSelectDevices([...selectedDeviceIds, currentDeviceId]);
    }

    const currentSelectedPatientIndex = selectedPatients.findIndex((p) => p.id === currentPatientId);

    if (currentSelectedPatientIndex > -1) {
      // this patients is already selected
      const pCopy: ISelectedPatient[] = JSON.parse(JSON.stringify(selectedPatients));
      const currentSelectedDeviceIndex = pCopy[currentSelectedPatientIndex].deviceIds.findIndex(
        (id) => id === currentDeviceId
      );

      if (currentSelectedDeviceIndex > -1) {
        // device is already selected, unselect
        pCopy[currentSelectedPatientIndex].deviceIds.splice(currentSelectedDeviceIndex, 1);
      } else {
        // device was not selected, select
        pCopy[currentSelectedPatientIndex].deviceIds.push(currentDeviceId);
      }

      if (pCopy[currentSelectedPatientIndex].deviceIds.length === 0) {
        pCopy.splice(currentSelectedPatientIndex, 1);
      }

      onSelectPatients(pCopy);
    } else {
      // this patient isn't selected yet
      onSelectPatients([...selectedPatients, { id: currentPatientId, deviceIds: [currentDeviceId] }]);
    }
  };

  const handleSelect = (record: TableRecord): void => {
    if (isDeviceRecord(record)) {
      handleDeviceRowClick(record);
    } else {
      handlePatientRowClick(record);
    }
  };

  const handleSelectAll = (_: any, selectedRows: TableRecord[]): void => {
    const allDevicesToSelect = selectedRows
      .filter(
        (record) =>
          !isDeviceRecord(record) && record.children.length > 0 && !record.children.some((d) => d.isPlaceholder)
      )
      .map((record) => (record as PatientRecord).children[0]);

    const allPatientsToSelect = selectedRows.filter((record) => !isDeviceRecord(record)) as PatientRecord[];

    let areAllDevicesIncluded = true;

    for (let i = 0; i < allDevicesToSelect.length; i += 1) {
      if (!selectedDeviceIds.includes(allDevicesToSelect[i].id)) {
        areAllDevicesIncluded = false;
        break;
      }
    }

    let devicesToSelect: string[] = [];
    let patientsToSelect: { id: string; deviceIds: string[] }[] = [];

    if (
      !areAllDevicesIncluded ||
      allDevicesToSelect.length !== selectedDeviceIds.length ||
      allDevicesToSelect.length === 0
    ) {
      devicesToSelect = allDevicesToSelect.map((d) => d.id);
      patientsToSelect = allPatientsToSelect.map((p) => ({ id: p.patient.id, deviceIds: [] }));

      allDevicesToSelect.forEach((device) => {
        const { id: deviceId, patientId } = device;

        const index = patientsToSelect.findIndex((p) => p.id === patientId);

        if (index > -1) {
          patientsToSelect[index].deviceIds.push(deviceId);
        }
      });

      allDevicesToSelect.map((device) => ({ id: device.patientId, deviceIds: [device.id] } as ISelectedPatient));
    }

    onSelectDevices(devicesToSelect);
    onSelectPatients(patientsToSelect);
  };

  const onTableChanged = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: any,
    extra: TableCurrentDataSource<TableRecord>
  ) => {
    setTablePagination((s) => ({ ...s, current: pagination.current, pageSize: pagination.pageSize }));

    const ids = extra.currentDataSource.map((item) => item.id);
    onSelectPatientOrder(ids);

    setDataConfig({ filteredInfo: _filters, sortedInfo: sorter });

    if (!sorter.order) {
      // don't send an HTTP request since on-screen order isn't changing
      return;
    }

    let sortBy: string;
    const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

    if (sorter && sorter.columnKey) {
      if (sorter.columnKey === 'shipmentDate') {
        sortBy = 'deviceReadyToShipOccurredAt';
      } else {
        sortBy = sorter.columnKey;
      }
    } else {
      sortBy = 'deviceReadyToShipOccurredAt';
    }

    const shouldUpdateSettings =
      pagingConfig === null ||
      pagingConfig.page !== pagination.current ||
      pagingConfig.pageLength !== (pagination.pageSize || pagination.defaultPageSize) ||
      pagingConfig.sortBy !== sortBy ||
      pagingConfig.sortOrder !== sortOrder;

    if (shouldUpdateSettings) {
      updatePagingConfig({
        page: pagination.current!,
        pageLength: pagination.pageSize!,
        sortBy,
        sortOrder,
        includeTotalCount: pagingConfig === null || pagingConfig.page === 1,
      });
    }
  };

  const selectedDevicesObjects = selectedDeviceIds.map((d) => getDeviceById(d)!);

  const selectedInStockDevices = selectedDevicesObjects.filter((d) => (d ? d.status === 'inStock' : false));

  const selectedReadyToShipDevices = selectedDevicesObjects.filter((d) => (d ? d.status === 'readyToShip' : false));

  const selectedAwaitingPickupDevices = selectedDevicesObjects.filter((d) =>
    d ? d.status === 'awaitingPickup' : false
  );

  const setReadyToShipStatus = async (): Promise<void> => {
    const theDevices = [...selectedInStockDevices, ...selectedReadyToShipDevices, ...selectedAwaitingPickupDevices];

    if (theDevices.length === 0) {
      return;
    }

    setDeviceLoading(true);

    await Promise.all(theDevices.map((d) => updateDevice(d.id, { status: 'readyToShip' }, false, false, true)));

    await reloadPage(true);

    setDeviceLoading(false);

    Notification({
      type: 'success',
      message: `${theDevices.length} device${theDevices.length === 1 ? ' was' : 's were'} successfully updated.`,
    });
  };

  const setAwaitingPickupStatus = async (): Promise<void> => {
    const theDevices = [...selectedInStockDevices, ...selectedReadyToShipDevices, ...selectedAwaitingPickupDevices];

    if (theDevices.length === 0) {
      return;
    }

    let hasError = false;
    theDevices.forEach((device: IDevice) => {
      // for prelabeled tubes, hrid should NOT be used and a barcode MUST be supplied.
      if (project.labelIdentifierSource === 'barcodePrelabeled') {
        if (device.barcode === undefined || device.barcode === device.hrid) {
          Notification({
            type: 'error',
            message: `Device ${device.id} does not have a barcode assigned.`,
          });
          hasError = true;
        }
      }
      // Validate if labels exist
      if (project.package1Type !== 'none' && !device.trackingNumberToPatient) {
        Notification({
          type: 'error',
          message: `Device ${device.id} does not have a "to patient" shipping label generated.`,
        });
        hasError = true;
      }

      if (project.package2Type !== 'none' && !device.trackingNumberToLab) {
        Notification({
          type: 'error',
          message: `Device ${device.id} does not have a "to lab" shipping label generated.`,
        });
        hasError = true;
      }

      // Validate lot exists
      if (!device.deviceLotId) {
        Notification({
          type: 'error',
          message: `Device ${device.id} does not have a Lot ID.`,
        });
        hasError = true;
      }

      // Validate expiration date exists
      if (!device.expiresAt) {
        Notification({
          type: 'error',
          message: `Device ${device.id} does not have an Expiration Date.`,
        });
        hasError = true;
      }
    });

    if (!hasError) {
      setDeviceLoading(true);

      await Promise.all(theDevices.map((d) => updateDevice(d.id, { status: 'awaitingPickup' }, false, false, true)));

      await reloadPage(true);

      setDeviceLoading(false);

      Notification({
        type: 'success',
        message: `${theDevices.length} device${theDevices.length === 1 ? ' was' : 's were'} successfully updated.`,
      });
    }
  };

  const selectedDeviceCountWithManualStatus =
    selectedInStockDevices.length + selectedReadyToShipDevices.length + selectedAwaitingPickupDevices.length;

  const handleCreateShippingLabels = (disposition?: string) => {
    createShippingLabels(disposition);
  };

  const handlePrintables = () => {
    generatePrintables(isOneClickPrintEnabled);
  };

  /**
   * This boolean is used to separate old school orders and devices and those
   * created using the upgraded kit item with attached files. If an order has
   * printables attached to it, use those to print all of them in one click.
   * Otherwise use the old approach and show a separate button for each
   * document type such as requisition forms and customer letters.
   */
  const selectedOrderHasPrintables = useMemo(() => {
    const selectedDevice = selectedDeviceIds.length === 1 ? getDeviceById(selectedDeviceIds[0]) : null;

    if (!selectedDevice) {
      return false;
    }

    const selectedOrder = selectedDevice.orderId ? ordersByOrderId[selectedDevice.orderId] : null;

    if (selectedOrder) {
      return Array.isArray(selectedOrder.printables) && selectedOrder.printables.length > 0;
    }

    return false;
  }, [selectedDeviceIds]);

  /**
   * This boolean is used to see if any of the selected order is fulfilled by a third party.
   * If it is, we will disallow printing labels
   */
  const selectedOrderIsFulfilledByThirdParty = useMemo(() => {
    let thirdPartyOrderFound = false;
    selectedDeviceIds.forEach((deviceId) => {
      const device = getDeviceById(deviceId);
      if (device) {
        const order = device.orderId ? ordersByOrderId[device.orderId] : null;
        if (order?.fulfillmentType === OrderFulfillmentType.BioTouch) {
          thirdPartyOrderFound = true;
        }
      }
    });
    return thirdPartyOrderFound;
  }, [selectedDeviceIds]);

  const shouldShowRequisitionFormButton = (): boolean => {
    if (selectedOrderHasPrintables) {
      return false;
    }

    // If we have no labs, or more than 1 lab (we currently don't support this) for a given project
    // Do not show the req form button
    // Otherwise, if a project has 1 lab and that 1 lab has a req form URL, show the req form button
    return projectLaboratories.length === 1 && !!laboratory?.reqFormKey;
  };

  /**
   * Returns `false` if selected patients have pending orders or
   * selected devices include replaced devices, or project is
   * "in-clinic", `true` otherwise.
   */
  const canSelectedDevicesBeUpdated = (): boolean => {
    // Only "direct to patient" distribution model is supported at this time.
    if (project.distributionModel !== 'directToPatient') {
      return false;
    }

    for (let i = 0; i < selectedPatients.length; i += 1) {
      const { deviceIds, id: patientId } = selectedPatients[i];
      const replacedOrCreatedForOrderDevice = deviceIds.some((id) => {
        const d = getDeviceById(id);
        const isReplaced = d?.isReplaced ?? false;
        const status = d?.status;
        return isReplaced || status === 'createdForOrder';
      });
      if (replacedOrCreatedForOrderDevice) {
        return false;
      }
      const hasPendingOrder = patientIdsWithPendingOrders.includes(patientId);
      if (hasPendingOrder) {
        return false;
      }
    }

    if (isArchivedProject) {
      return false;
    }

    return true;
  };

  /**
   * When device selections changes on the screen,
   */
  const getPackingInstructionsUploadIdForSelectedDevices = (): string | null => {
    const tempOrderTemplateIds = selectedDeviceIds.reduce<string[]>((acc, deviceId) => {
      const device = getDeviceById(deviceId);

      if (!device || !device.orderTemplateId) {
        // Important, do not delete. Takes care of devices from the pre- Order Template era.
        acc.push('');

        return acc;
      }

      // Drop index segments from the id, leave on the base up to and including template version.
      const orderTemplateId = device.orderTemplateId.split('::').splice(0, 3).join('::');

      acc.push(orderTemplateId);

      return acc;
    }, []);

    const orderTemplateIds = [...new Set(tempOrderTemplateIds)];

    if (orderTemplateIds.length === 0) {
      return null;
    }

    // Packing instructions cannot be shown for different order templates at the same time.
    if (orderTemplateIds.length > 1) {
      console.warn('Found multiple orderTemplateIds but expected at most one.', { orderTemplateIds });
      return null;
    }

    const orderTemplateId = orderTemplateIds[0];

    if (!orderTemplateId) {
      return null;
    }

    const uploadId = packingInstructions[orderTemplateId] ?? null;

    return uploadId;
  };
  /**
   * Requests a presigned URL for packing instruction from the API and then opens it in a new tab or window.
   */
  const openPackingInstructions = async (uploadId: string): Promise<void> => {
    await new Promise((resolve) => {
      getUpload(uploadId, { hydration: ['fileUrl'] }, (item) => {
        if (!item?.fileUrl) {
          console.error('Failed to open packing instructions', item);
          Notification({ type: 'error', message: 'Error', description: 'Failed to open packing instructions.' });
          resolve(false);
        } else {
          openDocument(item.fileUrl);
          resolve(true);
        }
      });
    });
  };

  // Determines if new devices can be added based on the current selection of patients and devices.
  const canAddDevice = useCallback(() => {
    if (selectedPatients.length !== 1) {
      return false;
    }

    return canSelectedDevicesBeUpdated();
  }, [selectedPatients, devices, deviceHashByDeviceId, patientIdsWithPendingOrders]);

  const canSetLotAndExpiry = useCallback(() => {
    if (selectedPatients.length === 0) {
      return false;
    }

    return canSelectedDevicesBeUpdated();
  }, [selectedPatients, devices, deviceHashByDeviceId, patientIdsWithPendingOrders]);

  const canDeleteDevice = selectedDeviceIds.length > 0;

  const canCancelOrders = useCallback(() => {
    if (selectedDevicesObjects.length !== selectedPatients.length) {
      return false;
    }

    const orderIdsFromSelectedDevices = selectedDevicesObjects
      .filter((entry) => entry && entry.orderId)
      .map((sdo) => sdo.orderId);
    if (orderIdsFromSelectedDevices.length === 0) {
      return false;
    }

    const ordersFromSelectedDevices = orders.filter((ord) => orderIdsFromSelectedDevices.includes(ord.id));
    if (ordersFromSelectedDevices.length === 0) {
      return false;
    }

    // `PendingOrderStatuses` is also the set of statuses in which an order may be cancelled.
    return ordersFromSelectedDevices.every((so) => PendingOrderStatuses.has(so.status));
  }, [selectedPatients, selectedDevicesObjects, orders]);

  const hasCustomerLetter = project.customerLetterKey !== null;

  const shipperButtonsDisabled: boolean =
    (project.distributionModel === 'directToPatient'
      ? selectedDeviceIds.length !== 1
      : selectedDeviceIds.length === 0) || selectedOrderIsFulfilledByThirdParty;

  const labelGenerationEnabled: boolean = selectedPatients.length !== 0 && !selectedOrderIsFulfilledByThirdParty;

  const singleInternalDeviceSelected: boolean = selectedDeviceIds.length === 1 && !selectedOrderIsFulfilledByThirdParty;

  const hasLabelingStrategy = project.labelingStrategy && project.labelingStrategy !== LABELING_STRATEGIES.none;

  function isDeviceVisible(device: IDevice, order?: Order): boolean {
    if (selectedOrderTemplateId === FILTER_SHOW_ALL) {
      return true;
    }

    // Do not show shippers 3PL orders
    if (isShipper && order && order.fulfillmentType === OrderFulfillmentType.BioTouch) {
      return false;
    }

    // Only show shippers readyToShip devices
    if (isShipper && device.status !== 'readyToShip') {
      return false;
    }
    if (selectedOrderTemplateId === NONE_TEMPLATE && device.orderTemplateId === null) {
      return true;
    }

    return device.orderTemplateId?.includes(selectedOrderTemplateId) ?? false;
  }

  const packingInstructionsUploadId = useMemo(() => {
    if (selectedDeviceIds.length === 0) {
      return null;
    }

    return getPackingInstructionsUploadIdForSelectedDevices();
  }, [selectedDeviceIds, packingInstructions, devices, orders]);

  return (
    <div ref={tableWrapRef} className="ShipmentRecipientsList">
      <div className={`${isAdmin ? 'padding-standard' : 'padding-shipper'}`}>
        Order Configuration:{' '}
        <SelectOrderOrderTemplate
          summarizedOrderTemplates={summarizedOrderTemplates}
          selectedOrderTemplateId={selectedOrderTemplateId}
          setSelectedOrderTemplateId={(selected: string) => {
            setSelectedOrderTemplateId(selected);
            resetPagination();
          }}
        />
      </div>
      <div className={`${isAdmin ? 'padding-standard' : 'padding-shipper'}`}>
        {isFiltered && (
          <Alert
            showIcon
            style={{ margin: '0 0 30px 0' }}
            type="info"
            message={
              <>
                Showing one patient.{' '}
                <Link
                  to={`/projects/${projectId}/shipments`}
                  onClick={() => {
                    onSelectPatients([]);
                    onSelectDevices([]);
                    onSelectPatientOrder([]);
                    resetPagination();
                    window.dispatchEvent(new Event('resize'));
                  }}
                >
                  <Button type="link" size="small">
                    Show all patients
                  </Button>
                </Link>
              </>
            }
          />
        )}
        <div style={{ display: 'flex' }}>
          <Table
            columns={columns}
            onChange={onTableChanged}
            rowSelection={{
              onSelect: handleSelect,
              onSelectAll: handleSelectAll,
              selectedRowKeys: [
                ...(selectedPatients.length
                  ? selectedPatients.filter((p) => p.deviceIds.length === 0).map((p) => p.id)
                  : []),
                ...selectedDeviceIds,
              ],
              checkStrictly: false,
            }}
            rowClassName={(record: TableRecord): string =>
              (record as PatientRecord).hasReplacementDeviceReadyToShip && isShipper ? 'replacement-recipient-row' : ''
            }
            bordered
            dataSource={patients
              .filter((patient: IPatient) =>
                (isAdmin || isCustomerService) && selectedOrderTemplateId === FILTER_SHOW_ALL
                  ? true
                  : getDevicesByPatientId(patient.id).filter((device: IDevice) =>
                      isDeviceVisible(device, device.orderId ? ordersByOrderId[device.orderId] : undefined)
                    ).length > 0
              )
              .map((patient: IPatient) => {
                let hasLabels = false;
                const hasPendingOrder = patientIdsWithPendingOrders.includes(patient.id);

                const patientDevices = getDevicesByPatientId(patient.id);
                // Filter out devices based on the selected Order Template
                const filteredPatientDevices = patientDevices
                  .filter((device: IDevice) =>
                    isDeviceVisible(device, device.orderId ? ordersByOrderId[device.orderId] : undefined)
                  )
                  // Filter out "pending" devices that order workflow still has to process
                  .filter((device: IDevice) => {
                    const deviceOrder = device.orderId ? ordersByOrderId[device.orderId] : undefined;

                    // Ignore (hide) devices where order status is "pending"
                    if (deviceOrder && isPendingOrder(deviceOrder, isAdmin, isCustomerService)) {
                      return false;
                    }
                    return true;
                  })
                  .map((device: IDevice): DeviceRecord => {
                    hasLabels = hasLabels || device.hasLabels;

                    return {
                      ...device,
                      lot:
                        device.deviceLotId && device.deviceLotId in deviceLotsHash
                          ? deviceLots[deviceLotsHash[device.deviceLotId]]?.key || ''
                          : '',
                      isDevice: true,
                      key: device.id,
                    };
                  });

                if (hasPendingOrder && selectedOrderTemplateId === FILTER_SHOW_ALL) {
                  // Add a temporary placeholder device row at the top so that it shows up in the UI.
                  // It's ONLY used to display a message, it's excluded from device counts.
                  const placeholderDevice = {
                    isDevice: true,
                    key: `${patient.id}-pending`,
                    status: 'createdForOrder',
                    isPlaceholder: true, // not an actual device, handle it differently
                    lot: '',
                  } as DeviceRecord;
                  filteredPatientDevices.unshift(placeholderDevice);
                }

                return {
                  id: patient.id,
                  patient,
                  key: patient.id,
                  isDevice: false,
                  hasReplacementDeviceReadyToShip:
                    patientDevices.filter((d) => d.replacesDeviceId && d.status === 'readyToShip').length > 0,
                  hasLabels,
                  children: filteredPatientDevices,
                  hasPendingOrder,
                };
              })}
            loading={loading}
            size="small"
            pagination={tablePagination}
            scroll={{ y: tableHeight }}
            showSorterTooltip={false}
            footer={
              () =>
                isAdmin || isCustomerService ? (
                  <Space size="small">
                    {(isAdmin || isShipper) && (
                      <Button
                        size="small"
                        type="link"
                        onClick={onCreatePatient}
                        disabled={isArchivedProject || orderingDisabledForProject}
                      >
                        <PlusCircleOutlined /> Add recipient
                      </Button>
                    )}

                    <Popconfirm
                      title={`Update ${selectedDeviceCountWithManualStatus} device${
                        selectedDeviceCountWithManualStatus === 1 ? '' : 's'
                      }?`}
                      onConfirm={setReadyToShipStatus}
                      okText={
                        <>
                          <CheckCircleOutlined style={{ marginRight: '5px' }} />
                          Set &#34;Ready to ship&#34;
                        </>
                      }
                      cancelText="Cancel"
                      disabled={selectedDeviceCountWithManualStatus === 0 || isArchivedProject}
                    >
                      {(isAdmin || isShipper) && (
                        <Button
                          disabled={selectedDeviceCountWithManualStatus === 0 || isArchivedProject}
                          size="small"
                          type="link"
                        >
                          <DropboxOutlined /> Set devices &#34;Ready to ship&#34;
                        </Button>
                      )}
                    </Popconfirm>

                    <Popconfirm
                      title={`Update ${selectedDeviceCountWithManualStatus} device${
                        selectedDeviceCountWithManualStatus === 1 ? '' : 's'
                      }?`}
                      onConfirm={setAwaitingPickupStatus}
                      okText={
                        <>
                          <CheckCircleOutlined style={{ marginRight: '5px' }} />
                          Set &#34;Awaiting pickup&#34;
                        </>
                      }
                      cancelText="Cancel"
                      disabled={selectedDeviceCountWithManualStatus === 0 || isArchivedProject}
                    >
                      {(isAdmin || isShipper) && (
                        <Button
                          size="small"
                          type="link"
                          disabled={selectedDeviceCountWithManualStatus === 0 || isArchivedProject}
                        >
                          <CarOutlined /> Set devices &#34;Awaiting pickup&#34;
                        </Button>
                      )}
                    </Popconfirm>
                    {(isAdmin || isShipper) && (
                      <Button
                        disabled={!singleInternalDeviceSelected || isArchivedProject}
                        size="small"
                        type="link"
                        onClick={() => handleCreateShippingLabels(getShipLabelDisposition())}
                      >
                        <PrinterOutlined /> Generate label(s)
                      </Button>
                    )}
                    {(isAdmin || isShipper) && (
                      <Button
                        disabled={!labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={() => generateRequisitionForm(getReqFormDisposition())}
                      >
                        <AuditOutlined /> Generate requisition form
                      </Button>
                    )}

                    {project.patientExperienceEnabled && (
                      <Button
                        disabled={!labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={() => generateActivationLabels(getActivationLabelsDisposition())}
                      >
                        <QrcodeOutlined /> Generate activation label
                      </Button>
                    )}

                    {hasLabelingStrategy && (
                      <Button
                        id="btn-gen-barcode"
                        disabled={!labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={() => generateBarcodes(getBarcodesDisposition())}
                      >
                        <BarcodeOutlined /> Generate barcode(s)
                      </Button>
                    )}
                    {(isAdmin || isShipper) && (
                      <Button
                        disabled={!canSetLotAndExpiry() || !labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={handleBulkUpdateDevices}
                      >
                        <CalendarOutlined /> Lot &amp; Expiry
                      </Button>
                    )}
                    {(isAdmin || isShipper) && (
                      <Button
                        disabled={!labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={() => generateOrderSummaryForm(getOrderSummaryDisposition())}
                      >
                        <PrinterOutlined /> Generate order summary
                      </Button>
                    )}
                    {(isAdmin || isShipper) && (
                      <Button
                        disabled={!selectedOrderHasPrintables || selectedOrderIsFulfilledByThirdParty}
                        size="small"
                        type="link"
                        onClick={() => handlePrintables()}
                      >
                        <PrinterOutlined /> Generate printables
                      </Button>
                    )}

                    {packingInstructionsUploadId !== null && (
                      <Button
                        size="small"
                        type="link"
                        onClick={() => openPackingInstructions(packingInstructionsUploadId)}
                      >
                        <DropboxOutlined /> Packing instructions
                      </Button>
                    )}

                    <Button
                      disabled={!canAddDevice() || orderingDisabledForProject}
                      size="small"
                      type="link"
                      onClick={addNewDevice}
                    >
                      <MedicineBoxOutlined /> Place order
                    </Button>

                    <Popconfirm
                      title={
                        <>
                          You are about to{' '}
                          <strong>
                            cancel {`${selectedDeviceIds.length}`} order
                            {`${selectedDeviceIds.length === 1 ? '' : 's'}`}
                          </strong>
                          . This operation <strong>cannot be undone</strong>. Proceed?
                        </>
                      }
                      onConfirm={cancelOrder}
                      okText="Cancel orders"
                      cancelText="Keep orders"
                      disabled={!canCancelOrders()}
                    >
                      {(isAdmin || isShipper) && (
                        <Button disabled={!canCancelOrders()} size="small" type="link">
                          <CloseSquareOutlined /> Cancel order(s)
                        </Button>
                      )}
                    </Popconfirm>

                    <Popconfirm
                      title={`Are you sure you want to delete ${selectedDeviceIds.length} device${
                        selectedDeviceIds.length === 1 ? '' : 's'
                      }?`}
                      onConfirm={deleteDevice}
                      okText="Delete"
                      cancelText="Cancel"
                      disabled={!canDeleteDevice}
                    >
                      {(isAdmin || isShipper) && (
                        <Button disabled={!canDeleteDevice} size="small" type="link">
                          <MedicineBoxOutlined /> Delete device(s)
                        </Button>
                      )}
                    </Popconfirm>

                    <Popconfirm
                      title="Are you sure want to cancel this label(s)?"
                      onConfirm={() => setImmediate(cancelShippingLabels)}
                      okText="Yes"
                      cancelText="No"
                      disabled={!singleInternalDeviceSelected}
                    >
                      {(isAdmin || isShipper) && (
                        <Button disabled={!singleInternalDeviceSelected} size="small" type="link">
                          <RollbackOutlined />
                          Cancel label(s)
                        </Button>
                      )}
                    </Popconfirm>

                    <Popconfirm
                      title={`Are you sure want to remove ${selectedPatients.length} recipient${
                        selectedPatients.length === 1 ? '' : 's'
                      }?`}
                      onConfirm={removePatients}
                      okText="Yes"
                      cancelText="No"
                      disabled={selectedPatients.length === 0}
                    >
                      {(isAdmin || isShipper) && (
                        <Button disabled={selectedPatients.length === 0} size="small" type="link">
                          <DeleteOutlined /> Remove recipient(s)
                        </Button>
                      )}
                    </Popconfirm>

                    {hasCustomerLetter && !selectedOrderHasPrintables && (isAdmin || isShipper) && (
                      <Button
                        disabled={!labelGenerationEnabled}
                        size="small"
                        type="link"
                        onClick={() => generateCustomerLetters(getCustomerLettersDisposition())}
                      >
                        <PrinterOutlined /> Generate customer letter(s)
                      </Button>
                    )}
                  </Space>
                ) : null // Hide the footer if criteria is not met
            }
          />
          {isShipper && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {packingInstructionsUploadId !== null && (
                <Button
                  size="large"
                  type="link"
                  onClick={() => openPackingInstructions(packingInstructionsUploadId)}
                  className="shipment-special-button"
                >
                  <div>Packing Instructions</div>
                  <DropboxOutlined style={{ fontSize: '55px' }} />
                </Button>
              )}

              <Button
                disabled={selectedDeviceIds.length === 0}
                size="large"
                type="link"
                onClick={handleBulkUpdateDevices}
                style={{
                  height: '90px',
                  boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%)',
                  margin: '0px 10px 25px 10px',
                  padding: '0px 5px 5px 5px',
                  borderRadius: '10px',
                }}
              >
                <div>Lot &amp; Expiry</div>
                <CalendarOutlined style={{ fontSize: '55px' }} />
              </Button>

              {hasLabelingStrategy && (
                <Button
                  disabled={shipperButtonsDisabled}
                  size="large"
                  type="link"
                  onClick={() => generateBarcodes(getBarcodesDisposition())}
                  className="shipment-button-standard"
                >
                  <div>Barcode</div> <BarcodeOutlined className="icon-large" />
                </Button>
              )}

              <Button
                disabled={shipperButtonsDisabled}
                size="large"
                type="link"
                onClick={() => handleCreateShippingLabels(getShipLabelDisposition())}
                className="shipment-button-standard"
              >
                <div>Shipping Label</div> <PrinterOutlined className="icon-large" />
              </Button>

              {selectedOrderHasPrintables && (
                <Button
                  disabled={shipperButtonsDisabled}
                  size="large"
                  type="link"
                  onClick={() => handlePrintables()}
                  className="shipment-button-standard"
                >
                  <div>Printables</div> <ProfileOutlined className="icon-large" />
                </Button>
              )}

              {project.patientExperienceEnabled && (
                <Button
                  disabled={selectedDeviceIds.length === 0}
                  size="large"
                  type="link"
                  onClick={() => generateActivationLabels(getActivationLabelsDisposition())}
                  className="shipment-button-standard"
                >
                  <div>Activation Label</div> <QrcodeOutlined className="icon-large" />
                </Button>
              )}

              {hasCustomerLetter && (
                <Button
                  disabled={shipperButtonsDisabled}
                  size="large"
                  type="link"
                  onClick={() => generateCustomerLetters(getCustomerLettersDisposition())}
                  className="shipment-button-standard"
                >
                  <div>Customer Letter</div>
                  <SolutionOutlined className="icon-large" />
                </Button>
              )}
              {shouldShowRequisitionFormButton() && (
                <Button
                  disabled={shipperButtonsDisabled}
                  size="large"
                  type="link"
                  onClick={() => generateRequisitionForm(getReqFormDisposition())}
                  className="shipment-button-standard"
                >
                  <div>Requisition Form</div>
                  <AuditOutlined className="icon-large" />
                </Button>
              )}
              <Button
                disabled={selectedDeviceIds.length === 0}
                size="large"
                type="link"
                onClick={() => generateOrderSummaryForm(getOrderSummaryDisposition())}
                className="shipment-button-standard"
              >
                <div>Order Summary</div>
                <FileDoneOutlined className="icon-large" />
              </Button>
              <Popconfirm
                title={`Update ${selectedDeviceCountWithManualStatus} device${
                  selectedDeviceCountWithManualStatus === 1 ? '' : 's'
                }?`}
                onConfirm={setAwaitingPickupStatus}
                okText={
                  <>
                    <CheckCircleOutlined /> Set &#34;Awaiting pickup&#34;
                  </>
                }
                cancelText="Cancel"
                disabled={selectedDeviceCountWithManualStatus === 0}
              >
                <Button
                  size="large"
                  type="link"
                  disabled={selectedDeviceCountWithManualStatus === 0}
                  className="shipment-button-standard"
                >
                  <div>Awaiting Pickup</div>
                  <CarOutlined className="icon-large" />
                </Button>
              </Popconfirm>
              <hr style={{ borderBottom: '1px solid #f0f0f0', width: '120px', marginBottom: '25px' }} />
              <Popconfirm
                title="Are you sure want to cancel this label(s)?"
                onConfirm={cancelShippingLabels}
                okText="Yes"
                cancelText="No"
                disabled={!singleInternalDeviceSelected}
              >
                <Button
                  disabled={!singleInternalDeviceSelected}
                  size="large"
                  type="link"
                  className="shipment-button-standard"
                >
                  <div>Cancel Label(s)</div>
                  <RollbackOutlined className="icon-large" />
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
        {(selectedPatients.length > 0 || selectedDeviceIds.length > 0) && (
          <div style={{ transform: 'translateY(-44px)', color: '#555', width: 'auto', display: 'inline-block' }}>
            Selected <strong>{selectedDeviceIds.length}</strong> {selectedDeviceIds.length === 1 ? 'device' : 'devices'}{' '}
            and <strong>{selectedPatients.length}</strong> {selectedPatients.length === 1 ? 'patient' : 'patients'}{' '}
            <span
              style={{ cursor: 'pointer', borderBottom: '1px dotted #004e92', color: '#004e92', fontSize: '0.9em' }}
              onClick={() => {
                onSelectDevices([]);
                onSelectPatients([]);
              }}
            >
              clear all
            </span>
          </div>
        )}
        {DeviceBulkEditModal}
      </div>
    </div>
  );
};

export default ShipmentRecipientsList;
